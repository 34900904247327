<template>
<div>
        <p>A USSD service code will give you exclusive ownership of the landing page for a service request. We support two variants of service codes:</p>
        <ul>
        <li>Dedicated service codes, which take the form of *144#. You get to choose what number you want.</li>
        <li>Shared service codes which take the form of *384*123#. You get to choose what channel you want (123 in this case)</li>
        </ul>
        


<b-card>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
        <b-form-group>
            <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
            </div>
        </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table :columns="columns" :rows="rows" :rtl="direction" :search-options="{
        enabled: true,
        externalQuery: searchTerm }" :pagination-options="{
        enabled: true,
        perPage:pageLength
      }">
        <template slot="table-row" slot-scope="props">
            <!-- Column: Status -->
            <span v-if="props.column.field === 'status'">
                <b-badge :variant="statusVariant(props.row.status)">
                    {{ props.row.status }}
                </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
                <span>
                    <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                        <template v-slot:button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                        </template>
                        <b-dropdown-item>
                            <feather-icon icon="Edit2Icon" class="mr-50" />
                            <span>Disabled</span>
                        </b-dropdown-item>
                        <b-dropdown-item>
                            <feather-icon icon="TrashIcon" class="mr-50" />
                            <span>Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
                {{ props.formattedRow[props.column.field] }}
            </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                        Showing 1 to
                    </span>
                    <b-form-select v-model="pageLength" :options="['3','5','10']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <span class="text-nowrap "> of {{ props.total }} entries </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value)=>props.pageChanged({currentPage:value})">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</b-card>
</div>
</template>

<script>
import $ from 'jquery'
import {
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard
} from 'bootstrap-vue'
import {
    VueGoodTable
} from 'vue-good-table'
import store from '@/store/index'

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BCard
    },
    data() {
        return {
            pageLength: 3,
            dir: false,
            columns: [{
                    label: 'Service Code',
                    field: 'code',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Search Service Code',
                    },
                },
                {
                    label: 'Network',
                    field: 'network',
                },
                {
                    label: 'Callback URL',
                    field: 'callbackUrl',
                },
                {
                    label: 'Status',
                    field: 'status',
                },

                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [
            ],
            searchTerm: '',
            status: [
            {
                1: 'Active',
                2: 'Pending',
                3: 'Rejected',
            }, {
                1: 'light-success',
                2: 'light-primary',
                3: 'light-danger',
            }],
        }
    },
    mounted() {
        this.getUSSDAccess()
    },
    computed: {
        statusVariant() {
            const statusColor = {
                /* eslint-disable key-spacing */
                Active: 'light-success',
                Pending: 'light-primary',
                Rejected: 'light-danger',
                /* eslint-enable key-spacing */
            }

            return status => statusColor[status]
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    methods: {
        getUSSDAccess(){
            let vm = this
            vm.isLoading = true
            $.get({
                url: store.state.rootUrl + 'ussd/v1/view/access_points',
                type: "get",
                async: true,
                data: {
                    limit: 50,
                },
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    if (response.data.code != 200) {
                        vm.$swal({
                            title: 'Error!',
                            text: response.data.message,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    } else {

                       vm.rows = response.data.data.data

                    }
                },
                error: function (jQxhr, status, error) {
                    vm.isLoading = false
                }
            });
        }
    }
   
}
</script>
